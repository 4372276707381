var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.title,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
            "ok-title": _vm.$t("button.select"),
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _vm.canEdit("DATAVIEW")
                      ? _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: { click: _vm.ok },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        )
                      : _vm._e(),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              class: { "mb-0": _vm.showNameError },
              attrs: {
                label: _vm.$t("dataview.field.name"),
                "label-for": "name",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name",
                      type: "text",
                      "data-vv-as": "Name",
                      "data-vv-name": "chart.name",
                      "data-vv-delay": "500",
                      state: _vm.fieldValidateUtil.stateValidate(
                        false,
                        _vm.veeFields,
                        _vm.errors,
                        "chart.name"
                      ),
                      autofocus: "",
                      trim: "",
                    },
                    model: {
                      value: _vm.chart.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.chart, "name", $$v)
                      },
                      expression: "chart.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v("  " + _vm._s(_vm.errors.first("chart.name")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.field.description"),
                "label-for": "dataview-description",
              },
            },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "dataview-description",
                  placeholder: _vm.$t("dataview.placeholder.description"),
                  "data-vv-as": _vm.$t("dataview.field.description"),
                  "data-vv-name": "chart.description",
                  "data-vv-delay": "500",
                  "max-rows": 6,
                  rows: 3,
                },
                model: {
                  value: _vm.chart.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.chart, "description", $$v)
                  },
                  expression: "chart.description",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _vm.supportsMultipleSeries
                ? _c("div", { staticClass: "mt-1" }, [
                    _c("label", { attrs: { for: "addseries" } }, [
                      _vm._v(_vm._s(_vm.$t("dataview.chart.series"))),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-action",
                        attrs: { name: "addseries" },
                        on: { click: _vm.addSeries },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "plus"] },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "chart-series" },
                _vm._l(_vm.chart.series, function (key, seriesIndex) {
                  return _c(
                    "div",
                    { key: seriesIndex, staticClass: "series-container" },
                    [
                      _vm.supportsMultipleSeries
                        ? _c(
                            "button",
                            {
                              staticClass: "series-remove-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.removeSeries(seriesIndex)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "trash-can"] },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "d-block",
                                  attrs: { for: "chartType" },
                                },
                                [_vm._v(_vm._s(_vm.$t("dataview.chart.type")))]
                              ),
                              _c("b-form-select", {
                                staticClass: "w-auto",
                                attrs: {
                                  name: "chartType",
                                  options: _vm.getChartTypes(seriesIndex),
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeType(seriesIndex)
                                  },
                                },
                                model: {
                                  value: _vm.chart.series[seriesIndex].type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.chart.series[seriesIndex],
                                      "type",
                                      $$v
                                    )
                                  },
                                  expression: "chart.series[seriesIndex].type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("b-col", [
                            _c(
                              "div",
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-block",
                                    attrs: { for: "legendposition" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("dataview.chart.legend"))
                                    ),
                                  ]
                                ),
                                _c("b-form-select", {
                                  staticClass: "w-auto mt-1",
                                  attrs: {
                                    name: "legendposition",
                                    options: _vm.legendPositions,
                                  },
                                  on: { change: _vm.changeLegendPosition },
                                  model: {
                                    value: _vm.chart.legend.position,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.chart.legend,
                                        "position",
                                        $$v
                                      )
                                    },
                                    expression: "chart.legend.position",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "w-auto mt-3 mb-3",
                          attrs: { name: "fit" },
                          model: {
                            value: _vm.chart.fit,
                            callback: function ($$v) {
                              _vm.$set(_vm.chart, "fit", $$v)
                            },
                            expression: "chart.fit",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("dataview.chart.fit")))]
                      ),
                      _vm.isPie(seriesIndex)
                        ? [
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-block",
                                    attrs: { for: "labelkey" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("dataview.chart.labelkey"))
                                    ),
                                  ]
                                ),
                                _c("b-form-select", {
                                  staticClass: "w-auto",
                                  attrs: {
                                    name: "labelkey",
                                    options: _vm.fields.map(function (f) {
                                      return typeof f === "string"
                                        ? f
                                        : f.agFunction.toUpperCase() +
                                            "(" +
                                            f.field +
                                            ")"
                                    }),
                                  },
                                  model: {
                                    value:
                                      _vm.chart.series[seriesIndex].labelKey,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.chart.series[seriesIndex],
                                        "labelKey",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "chart.series[seriesIndex].labelKey",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c("label", { attrs: { for: "ykeys" } }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataview.chart.anglekey"))
                                  ),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-action mr-3",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addAngleKey(seriesIndex)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "plus"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c("BadgeGroup", {
                                  staticClass: "d-inline-block w-100 mb-3",
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var item = ref.item
                                          var index = ref.index
                                          return [
                                            _c("Badge", {
                                              key: index,
                                              attrs: {
                                                text: _vm.valueText(item),
                                                variant: "white",
                                                pillable:
                                                  item !== null &&
                                                  !!item.pillable,
                                              },
                                              on: {
                                                badgeRemove: function ($event) {
                                                  return _vm.removeAngleKey(
                                                    seriesIndex
                                                  )
                                                },
                                                badgeClick: function ($event) {
                                                  return _vm.editAngleName(
                                                    seriesIndex
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value:
                                      _vm.chart.series[seriesIndex].angleKeys,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.chart.series[seriesIndex],
                                        "angleKeys",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "chart.series[seriesIndex].angleKeys",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.chart.series[seriesIndex].type !== null &&
                      !_vm.isPie(seriesIndex) &&
                      !_vm.isBar(seriesIndex)
                        ? _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "mr-2", attrs: { for: "xkey" } },
                                [_vm._v(_vm._s(_vm.$t("dataview.chart.xkey")))]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-action mr-3",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addXName(seriesIndex)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "plus"] },
                                  }),
                                ],
                                1
                              ),
                              _c("BadgeGroup", {
                                staticClass: "d-inline-block w-100 mb-3",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text: _vm.valueText(item),
                                              variant: "white",
                                              pillable:
                                                item !== null &&
                                                !!item.pillable,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.removeXKey(
                                                  seriesIndex,
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.editXName(
                                                  seriesIndex,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.chart.series[seriesIndex].xKey,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.chart.series[seriesIndex],
                                      "xKey",
                                      $$v
                                    )
                                  },
                                  expression: "chart.series[seriesIndex].xKey",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.chart.series[seriesIndex].type !== null &&
                      !_vm.isPie(seriesIndex) &&
                      !_vm.isBar(seriesIndex) &&
                      !_vm.isHistogram(seriesIndex)
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "dataview.field.format_horizontal"
                                ),
                                "label-for": "format",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "format",
                                      type: "text",
                                      "data-vv-as": "Name",
                                      "data-vv-name": "chart.format",
                                      "data-vv-delay": "500",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.chart.format,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.chart, "format", $$v)
                                      },
                                      expression: "chart.format",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.chart.series[seriesIndex].type !== null &&
                      !_vm.isPie(seriesIndex) &&
                      _vm.chart.series[seriesIndex].type !== "histogram"
                        ? _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c("label", { attrs: { for: "ykeys" } }, [
                                _vm._v(_vm._s(_vm.yKeysTitle(seriesIndex))),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-action mr-3",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addYKey(seriesIndex)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "plus"] },
                                  }),
                                ],
                                1
                              ),
                              _vm.chart.series[seriesIndex].type === "bar" ||
                              _vm.chart.series[seriesIndex].type === "column" ||
                              _vm.chart.series[seriesIndex].type === "area"
                                ? _c(
                                    "b-form-group",
                                    { staticClass: "float-right" },
                                    [
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "d-inline-block mr-4",
                                          attrs: {
                                            name: "grouped",
                                            value: false,
                                          },
                                          model: {
                                            value:
                                              _vm.chart.series[seriesIndex]
                                                .grouped,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.chart.series[seriesIndex],
                                                "grouped",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "chart.series[seriesIndex].grouped",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("dataview.chart.stacked")
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-form-radio",
                                        {
                                          staticClass: "d-inline-block mr-4",
                                          attrs: {
                                            name: "grouped",
                                            value: true,
                                          },
                                          model: {
                                            value:
                                              _vm.chart.series[seriesIndex]
                                                .grouped,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.chart.series[seriesIndex],
                                                "grouped",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "chart.series[seriesIndex].grouped",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("dataview.chart.grouped")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("BadgeGroup", {
                                staticClass: "d-inline-block w-100 mb-3",
                                on: {
                                  change: function ($event) {
                                    var i = arguments.length,
                                      argsArray = Array(i)
                                    while (i--) argsArray[i] = arguments[i]
                                    return _vm.yChange.apply(
                                      void 0,
                                      [seriesIndex].concat(argsArray)
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text: _vm.valueText(item),
                                              variant: "white",
                                              pillable:
                                                item !== null &&
                                                !!item.pillable,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.removeYKey(
                                                  seriesIndex,
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.editYName(
                                                  seriesIndex,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.chart.series[seriesIndex].yKeys,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.chart.series[seriesIndex],
                                      "yKeys",
                                      $$v
                                    )
                                  },
                                  expression: "chart.series[seriesIndex].yKeys",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.chart.series[seriesIndex].type === "bubble"
                        ? _c(
                            "div",
                            { staticClass: "size-axis-container" },
                            [
                              _c(
                                "label",
                                { staticClass: "mr-2", attrs: { for: "xkey" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataview.chart.sizekey"))
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-action mr-3",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editSizeName(seriesIndex)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "plus"] },
                                  }),
                                ],
                                1
                              ),
                              _c("BadgeGroup", {
                                staticClass: "d-inline-block w-100 mb-3",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text: _vm.valueText(item),
                                              variant: "white",
                                              pillable:
                                                item !== null &&
                                                !!item.pillable,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.removeSizeKey(
                                                  seriesIndex,
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.editSizeName(
                                                  seriesIndex
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.chart.series[seriesIndex].sizeKey,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.chart.series[seriesIndex],
                                      "sizeKey",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "chart.series[seriesIndex].sizeKey",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.chart.series[seriesIndex].type !== null &&
                      _vm.isBar(seriesIndex)
                        ? _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "label",
                                { staticClass: "mr-2", attrs: { for: "xkey" } },
                                [_vm._v(_vm._s(_vm.$t("dataview.chart.ykeys")))]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-action mr-3",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addXName(seriesIndex)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "plus"] },
                                  }),
                                ],
                                1
                              ),
                              _c("BadgeGroup", {
                                staticClass: "d-inline-block w-100 mb-3",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text: _vm.valueText(item),
                                              variant: "white",
                                              pillable:
                                                item !== null &&
                                                !!item.pillable,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.removeXKey(
                                                  seriesIndex,
                                                  index
                                                )
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.editXName(
                                                  seriesIndex,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.chart.series[seriesIndex].xKey,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.chart.series[seriesIndex],
                                      "xKey",
                                      $$v
                                    )
                                  },
                                  expression: "chart.series[seriesIndex].xKey",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.chart.series[seriesIndex].type !== null &&
                      _vm.isBar(seriesIndex)
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("dataview.field.format_vertical"),
                                "label-for": "format",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "format",
                                      type: "text",
                                      "data-vv-as": "Name",
                                      "data-vv-name": "chart.format",
                                      "data-vv-delay": "500",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.chart.format,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.chart, "format", $$v)
                                      },
                                      expression: "chart.format",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              ),
              _vm.showAxesSettings &&
              _vm.chart.series.length > 0 &&
              !_vm.isPie(0)
                ? _c(
                    "label",
                    {
                      staticClass: "text-n-line mt-4",
                      class: _vm.axesExpanded ? null : "collapsed",
                      attrs: {
                        "aria-expanded": _vm.axesExpanded ? "true" : "false",
                        "aria-controls": "collapse-4",
                      },
                      on: {
                        click: function ($event) {
                          _vm.axesExpanded = !_vm.axesExpanded
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        [
                          _vm._v(_vm._s(_vm.$t("dataview.chart.axes")) + " "),
                          _vm.axesExpanded
                            ? _c("font-awesome-icon", {
                                staticClass: "collapsable-icon",
                                attrs: { icon: ["far", "chevron-up"] },
                              })
                            : _c("font-awesome-icon", {
                                staticClass: "collapsable-icon",
                                attrs: { icon: ["far", "chevron-down"] },
                              }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-collapse",
                {
                  staticClass: "mt-2",
                  attrs: { id: "collapse-4" },
                  model: {
                    value: _vm.axesExpanded,
                    callback: function ($$v) {
                      _vm.axesExpanded = $$v
                    },
                    expression: "axesExpanded",
                  },
                },
                [
                  _c("div", { staticClass: "mt-1" }, [
                    _c("label", { attrs: { for: "addseries" } }, [
                      _vm._v(_vm._s(_vm.$t("dataview.chart.addaxes"))),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn-action",
                        attrs: { name: "addaxes" },
                        on: { click: _vm.addAxes },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "plus"] },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.chart.series.length > 0 && !_vm.isPie(0)
                    ? _c(
                        "div",
                        { staticClass: "chart-axes" },
                        _vm._l(_vm.chart.axes, function (key, axesIndex) {
                          return _c(
                            "div",
                            { key: axesIndex, staticClass: "axes-container" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "series-remove-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAxes(axesIndex)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "trash-can"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "align-bottom mr-2",
                                  attrs: { for: "axestype" },
                                },
                                [_vm._v(_vm._s(_vm.$t("dataview.chart.type")))]
                              ),
                              _c("b-form-select", {
                                staticClass: "w-auto mt-1",
                                attrs: {
                                  name: "axestype",
                                  options: _vm.axesTypes,
                                },
                                model: {
                                  value: _vm.chart.axes[axesIndex].type,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.chart.axes[axesIndex],
                                      "type",
                                      $$v
                                    )
                                  },
                                  expression: "chart.axes[axesIndex].type",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "align-bottom ml-4 mr-2",
                                  attrs: { for: "axesposition" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataview.chart.position"))
                                  ),
                                ]
                              ),
                              _c("b-form-select", {
                                staticClass: "w-auto mt-1",
                                attrs: {
                                  name: "axesposition",
                                  options: _vm.axesPositions,
                                },
                                model: {
                                  value: _vm.chart.axes[axesIndex].position,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.chart.axes[axesIndex],
                                      "position",
                                      $$v
                                    )
                                  },
                                  expression: "chart.axes[axesIndex].position",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "align-bottom ml-4 mr-2",
                                  attrs: { for: "labelrotation" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("dataview.chart.labelrotation")
                                    )
                                  ),
                                ]
                              ),
                              _c("b-form-input", {
                                staticClass: "w-auto d-inline-block",
                                attrs: { type: "number" },
                                model: {
                                  value:
                                    _vm.chart.axes[axesIndex].label.rotation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.chart.axes[axesIndex].label,
                                      "rotation",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "chart.axes[axesIndex].label.rotation",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "align-bottom mr-2",
                                  attrs: { for: "axestype" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("dataview.chart.axestitle"))
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "name-edit" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-inline-block",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.editAxesTitle(axesIndex)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "name-label d-inline-block",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.chart.axes[axesIndex].title
                                                .text !== null &&
                                                _vm.chart.axes[axesIndex].title
                                                  .text !== ""
                                                ? _vm.chart.axes[axesIndex]
                                                    .title.text
                                                : _vm.$t(
                                                    "dataview.placeholder.name"
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-inline-block ml-2 mr-2",
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "edit-icon-color",
                                          attrs: {
                                            icon: ["far", "pen-to-square"],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("label", { staticClass: "mr-1" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("dataview.chart.key"))
                                    ),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-action",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addAxesKey(axesIndex)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("BadgeGroup", {
                                    staticClass: "d-inline-block w-100 mb-3",
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var item = ref.item
                                            var index = ref.index
                                            return [
                                              _c("Badge", {
                                                key: index,
                                                attrs: {
                                                  text: _vm.valueText(item),
                                                  variant: "white",
                                                  pillable:
                                                    item !== null &&
                                                    !!item.pillable,
                                                },
                                                on: {
                                                  badgeRemove: function (
                                                    $event
                                                  ) {
                                                    return _vm.removeAxesKey(
                                                      axesIndex,
                                                      index
                                                    )
                                                  },
                                                  badgeClick: function (
                                                    $event
                                                  ) {
                                                    return _vm.editAxesKey(
                                                      axesIndex,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.chart.axes[axesIndex].keys,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.chart.axes[axesIndex],
                                          "keys",
                                          $$v
                                        )
                                      },
                                      expression: "chart.axes[axesIndex].keys",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.pick_colour"),
            size: "sm",
            "ok-title": _vm.$t("button.confirm"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.colourPickerOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.promptColour,
            callback: function ($$v) {
              _vm.promptColour = $$v
            },
            expression: "promptColour",
          },
        },
        [
          _c("chrome-picker", {
            staticClass: "colour-picker",
            model: {
              value: _vm.colour,
              callback: function ($$v) {
                _vm.colour = $$v
              },
              expression: "colour",
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.edit_field"),
            size: "md",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.xNameEditOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          disabled: _vm.xKeyEditVal === null,
                          size: "sm",
                          variant: "success",
                        },
                        on: { click: _vm.xNameEditOk },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.xNameEditShow,
            callback: function ($$v) {
              _vm.xNameEditShow = $$v
            },
            expression: "xNameEditShow",
          },
        },
        [
          _c("label", { staticClass: "d-block mr-1" }, [
            _vm._v(_vm._s(_vm.$t("dataview.chart.field_name"))),
          ]),
          _c("b-form-select", {
            staticClass: "d-block w-auto",
            attrs: { options: _vm.xFields() },
            on: { change: _vm.changeXKey },
            model: {
              value: _vm.xKeyEditVal,
              callback: function ($$v) {
                _vm.xKeyEditVal = $$v
              },
              expression: "xKeyEditVal",
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("dataview.chart.display_name"),
                "label-for": "xname",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.xNameEditVal,
                      callback: function ($$v) {
                        _vm.xNameEditVal = $$v
                      },
                      expression: "xNameEditVal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.edit_field"),
            size: "md",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.sizeNameEditOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          disabled: _vm.sizeKeyEditVal === null,
                          size: "sm",
                          variant: "success",
                        },
                        on: { click: _vm.sizeNameEditOk },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.sizeNameEditShow,
            callback: function ($$v) {
              _vm.sizeNameEditShow = $$v
            },
            expression: "sizeNameEditShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: "danger",
                dismissible: "",
                show: _vm.showSizeError,
              },
              on: { dismissed: _vm.dismissSizeAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertSizeMsg) + " "),
            ],
            1
          ),
          _c("label", { staticClass: "d-block mr-1" }, [
            _vm._v(_vm._s(_vm.$t("dataview.chart.field_name"))),
          ]),
          _c("b-form-select", {
            staticClass: "d-block w-auto",
            attrs: { name: "sizeKey", options: _vm.numberFields },
            on: { change: _vm.changeSizeKey },
            model: {
              value: _vm.sizeKeyEditVal,
              callback: function ($$v) {
                _vm.sizeKeyEditVal = $$v
              },
              expression: "sizeKeyEditVal",
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("dataview.chart.display_name"),
                "label-for": "sizename",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.sizeNameEditVal,
                      callback: function ($$v) {
                        _vm.sizeNameEditVal = $$v
                      },
                      expression: "sizeNameEditVal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.edit_field"),
            size: "md",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.yNameEditOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          disabled: _vm.yKeyEditVal === null,
                          size: "sm",
                          variant: "success",
                        },
                        on: { click: _vm.yNameEditOk },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.yNameEditShow,
            callback: function ($$v) {
              _vm.yNameEditShow = $$v
            },
            expression: "yNameEditShow",
          },
        },
        [
          _c("label", { staticClass: "d-block mr-1" }, [
            _vm._v(_vm._s(_vm.$t("dataview.chart.field_name"))),
          ]),
          _c("b-form-select", {
            staticClass: "d-block w-auto mt-1",
            attrs: { name: "ykeys", options: _vm.numberFields },
            on: { change: _vm.changeYKey },
            model: {
              value: _vm.yKeyEditVal,
              callback: function ($$v) {
                _vm.yKeyEditVal = $$v
              },
              expression: "yKeyEditVal",
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("dataview.chart.display_name"),
                "label-for": "yname",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.yNameEditVal,
                      callback: function ($$v) {
                        _vm.yNameEditVal = $$v
                      },
                      expression: "yNameEditVal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.edit_field"),
            size: "md",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.axesKeyEditOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.axesKeyEditShow,
            callback: function ($$v) {
              _vm.axesKeyEditShow = $$v
            },
            expression: "axesKeyEditShow",
          },
        },
        [
          _c("label", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.$t("dataview.chart.field_name"))),
          ]),
          _c("b-form-select", {
            staticClass: "w-auto mt-1",
            attrs: { name: "axeskeys", options: _vm.fields },
            model: {
              value: _vm.axesKeyEditVal,
              callback: function ($$v) {
                _vm.axesKeyEditVal = $$v
              },
              expression: "axesKeyEditVal",
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.edit_name"),
            size: "sm",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.axesTitleEditOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.axesTitleEditShow,
            callback: function ($$v) {
              _vm.axesTitleEditShow = $$v
            },
            expression: "axesTitleEditShow",
          },
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("dataview.chart.axestitle"),
                "label-for": "axestitle",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.axesTitleEditVal,
                      callback: function ($$v) {
                        _vm.axesTitleEditVal = $$v
                      },
                      expression: "axesTitleEditVal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("dataview.confirmation.edit_field"),
            size: "md",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
          },
          on: { ok: _vm.angleNameEditOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          disabled: _vm.angleKeyEditVal === null,
                          size: "sm",
                          variant: "success",
                        },
                        on: { click: _vm.angleNameEditOk },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.angleNameEditShow,
            callback: function ($$v) {
              _vm.angleNameEditShow = $$v
            },
            expression: "angleNameEditShow",
          },
        },
        [
          _c("label", { staticClass: "d-block mr-1" }, [
            _vm._v(_vm._s(_vm.$t("dataview.chart.field_name"))),
          ]),
          _c("b-form-select", {
            staticClass: "d-block w-auto mt-1",
            attrs: { name: "anglekeys", options: _vm.numberFields },
            on: { change: _vm.changeAngleKey },
            model: {
              value: _vm.angleKeyEditVal,
              callback: function ($$v) {
                _vm.angleKeyEditVal = $$v
              },
              expression: "angleKeyEditVal",
            },
          }),
          _c(
            "b-form-group",
            {
              staticClass: "mt-2",
              attrs: {
                label: _vm.$t("dataview.chart.display_name"),
                "label-for": "yname",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.angleNameEditVal,
                      callback: function ($$v) {
                        _vm.angleNameEditVal = $$v
                      },
                      expression: "angleNameEditVal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }