<template>
  <b-modal v-model="modalShow" size="lg" @shown="initModal" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="hidden"
      scrollable>

      <div class="history-item audit-detail-height">
        <div><span>{{ $t('sandbox.entity') }}: </span><span>{{ entity }}</span></div>
        <div><span>{{ $t('name') }}: </span><span>{{ name }}</span></div>
        <div><span>{{ $t('status') }}: </span><span>{{ getStatus(status) }}</span></div>
        <template v-for="(prop, index) in properties">
          <div :key="index">
            <span v-if="shouldDisplay(prop.name)">{{ prop.name }}: </span>
            <span v-if="shouldDisplay(prop.name)">{{ formatValue(prop) }}</span>
          </div>
        </template>
        <div v-if="dependencies || parent">{{ $t('sandbox.links') }}</div>
        <ul class="audit-detail">
          <li v-if="parent" :key="parent.uuId">
            <div><span>{{ $t('sandbox.entity') }}: </span><span>{{ parent.entity }}</span></div>
            <div><span>{{ $t('name') }}: </span><span>{{ parent.name }}</span></div>
            <div><span>{{ $t('status') }}: </span><span>{{ parent.status }}</span></div>
            <div v-if="parent.properties && (parent.properties.et || parent.properties.at)"><span>{{ $t('sandbox.modified') }}: </span><span>{{ formatDate(parent.properties.et ? parent.properties.et : parent.properties.at) }}</span></div>
          </li>
          <template v-for="(dependency, index) in dependencies">
            <li :key="index">
              <div><span>{{ $t('sandbox.entity') }}: </span><span>{{ dependency.entity }}</span></div>
              <div><span>{{ $t('name') }}: </span><span>{{ dependency.name }}</span></div>
              <div><span>{{ $t('status') }}: </span><span>{{ dependency.status }}</span></div>
              <div v-if="dependency.properties"><span>{{ $t('sandbox.modified') }}: </span><span>{{ formatDate(dependency.properties.et ? dependency.properties.et : dependency.properties.at) }}</span></div>
            </li>
          </template>
        </ul>
      
      </div>

    <template v-slot:modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.close') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { sandboxService, userService } from '@/services';
import { extractDurationConversionOpts, convertDurationToDisplay } from '@/helpers/task-duration-process';

export default {
  name: 'AuditModal'
  , components: {

  }
  , props: {
    id: {
      type: String
      , required: true
    }
    , show: {
      type: Boolean
      , required: true
    }
    , labelTitle: {
      type: String
      , default: 'Detail'
    }
    , customFields: {
      type: Array
      , default: null
    }
    , links: {
      type: String
      , default: 'NONE'
    }
    , parent: {
      type: Object
      , default: null
    }
  }
  , data: function() {
    return {
      gridOptions: null
      , gridApi: null
      , columnDefs: null
      , context: null
      , defaultColDef: null
      , modalShow: false

      , isManualTriggered: false
      , height: 'calc(100vh - 210px)'
      , width: '100%'
      , descriptionColWidth: 0
      , scrollbarWidth: -1
      , columnWidth: -1
      , durationConversionOpts: {}
      
      , entity: null
      , name: null
      , status: null
      , dependencies: null
      , properties: null
    }
  }
  , created() {
    this.getDurationConversionOpts();

    sandboxService.detail(this.$store.state.sandbox.value, this.id).then(response => {
      let data = response.data[response.data.jobCase];
      this.entity = data[0].entity;
      this.name = data[0].name;
      this.status = data[0].status;
      this.dependencies = data[0].dependencies;
      this.properties = data[0].properties;
      if (this.properties) {
        for (const key of Object.keys(this.properties)) {
          this.properties[key]['name'] = key;
        }
      }
    })
    .catch(function(error) {
      console.error(error); // eslint-disable-line no-console
      self.gridApi.hideOverlay();
      self.$nextTick(() => {
        self.gridApi.showLoadingOverlay();
        document.getElementById('history-grid-loading-overlay').innerHTML = self.$t('error.grid_data_loading');
      });
    });
    
  }
  , beforeMount() {
    
  }
  , mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.modalShow = this.show;
  }
  , beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.gridApi = null;
  }
  , watch: {
    show(newValue) {
      //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
      if(!newValue) {
        this.gridApi = null;
      }
      
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        if(this.gridApi != null) {
          this.gridApi.refreshServerSide({ purge: true }); //Force reloading data from server.
        }
      }
      
    }
  }
  , computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay' id="history-grid-loading-overlay">${ this.$t('history.grid.loading') }</span>`;
    }
  }
  , methods: {
    buildParams({ request: {/** sortModel, */endRow, startRow} }) {
      const params = {
        start: startRow,
        limit: endRow - startRow + 1,
      };
      return params;
    }
    , initModal() {

    }
    , hidden() {
      this.$emit('update:show', false);
    }
    , calcHeight(mode) {
      const isDocHeightLonger = document.body.scrollHeight - window.innerHeight > 0;
      if(isDocHeightLonger) {
        if(mode == 'xs') {
          const newHeight =  `${window.innerHeight - 170}px`;
          if(newHeight != this.height) {
            this.height = newHeight;
          }
        } else {
          const newHeight =  `${window.innerHeight - 210}px`;
          if(newHeight != this.height) {
            this.height = newHeight;
          }
        }
      } else {
        const newHeight = 'calc(100vh - 210px)'
        if(newHeight != this.height) {
          this.height = newHeight;
        }
      }
    }
    , getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        console.error(e); //eslint-disable-line no-console
      });
    }
    , formatDate(value) {
      return new Date(value).toLocaleString();
    }
    , formatValue(prop) {
      if (prop.name === 'startTime' ||
          prop.name === 'closeTime' ||
          prop.name === 'beginDate' ||
          prop.name === 'untilDate') {
        return this.formatDate(prop.value);  
      }
      else if (prop.name === 'duration') {
        return convertDurationToDisplay(prop.value / 60000, 'D', this.durationConversionOpts);
      }
      return prop.value;
    }
    , shouldDisplay(name) {
      if (name === 'avatarRef' ||
          name === 'bannerRef' ||
          name === 'name') {
        return false;
      }
    }
    , getStatus(status) {
      if (status === 'FORMED') {
        return 'CREATED';
      }
      return status;
    }
  }

}
</script>

<style lang="scss" scoped>
.history-grid-height {
  height: calc(100vh - 210px);
}
.audit-detail-height {
  min-height: 300px;
}
</style>