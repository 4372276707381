var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        size: "lg",
        title: _vm.labelTitle,
        "footer-class": "footerClass",
        "no-close-on-backdrop": "",
        "content-class": "shadow",
        scrollable: "",
      },
      on: { shown: _vm.initModal, hidden: _vm.hidden },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "danger" },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.close")))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.modalShow,
        callback: function ($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow",
      },
    },
    [
      _c(
        "div",
        { staticClass: "history-item audit-detail-height" },
        [
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.$t("sandbox.entity")) + ": ")]),
            _c("span", [_vm._v(_vm._s(_vm.entity))]),
          ]),
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.$t("name")) + ": ")]),
            _c("span", [_vm._v(_vm._s(_vm.name))]),
          ]),
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.$t("status")) + ": ")]),
            _c("span", [_vm._v(_vm._s(_vm.getStatus(_vm.status)))]),
          ]),
          _vm._l(_vm.properties, function (prop, index) {
            return [
              _c("div", { key: index }, [
                _vm.shouldDisplay(prop.name)
                  ? _c("span", [_vm._v(_vm._s(prop.name) + ": ")])
                  : _vm._e(),
                _vm.shouldDisplay(prop.name)
                  ? _c("span", [_vm._v(_vm._s(_vm.formatValue(prop)))])
                  : _vm._e(),
              ]),
            ]
          }),
          _vm.dependencies || _vm.parent
            ? _c("div", [_vm._v(_vm._s(_vm.$t("sandbox.links")))])
            : _vm._e(),
          _c(
            "ul",
            { staticClass: "audit-detail" },
            [
              _vm.parent
                ? _c("li", { key: _vm.parent.uuId }, [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("sandbox.entity")) + ": "),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.parent.entity))]),
                    ]),
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.$t("name")) + ": ")]),
                      _c("span", [_vm._v(_vm._s(_vm.parent.name))]),
                    ]),
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.$t("status")) + ": ")]),
                      _c("span", [_vm._v(_vm._s(_vm.parent.status))]),
                    ]),
                    _vm.parent.properties &&
                    (_vm.parent.properties.et || _vm.parent.properties.at)
                      ? _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("sandbox.modified")) + ": "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(
                                  _vm.parent.properties.et
                                    ? _vm.parent.properties.et
                                    : _vm.parent.properties.at
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._l(_vm.dependencies, function (dependency, index) {
                return [
                  _c("li", { key: index }, [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("sandbox.entity")) + ": "),
                      ]),
                      _c("span", [_vm._v(_vm._s(dependency.entity))]),
                    ]),
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.$t("name")) + ": ")]),
                      _c("span", [_vm._v(_vm._s(dependency.name))]),
                    ]),
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.$t("status")) + ": ")]),
                      _c("span", [_vm._v(_vm._s(dependency.status))]),
                    ]),
                    dependency.properties
                      ? _c("div", [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("sandbox.modified")) + ": "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(
                                  dependency.properties.et
                                    ? dependency.properties.et
                                    : dependency.properties.at
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              }),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }